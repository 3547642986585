.caption {
    transition: padding 0.6s;
}

a {
    transition: color 0.4s, background 0.4s;
}

.bx-controls a {
    transition: none;
}

* {
    transition: width 0.5s;
}

.grow {
    transition: all .3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}