h1,h2,h3,h4,h5,h6{
    margin-top: 0;
}


// gets the div out of container
.breakout{
    margin-left: -100% !important; /* old browsers fallback */
    margin-right: -100% !important;
    
    margin-left: calc(50% - 50vw) !important;
    margin-right: calc(50% - 50vw) !important;
}

//todo-made: improve?
.ajax-loader{
    display: none;
    position: absolute;
    margin: auto;
    top: 25%;
    left: 10%;
    bottom: 25%;
    right: 0px;
    z-index: 400000;
}