#navbar {
    border-bottom: 2px solid $primary;
    padding-bottom: $pad-medium;
}

.navbar {
    @media(max-width: $screen-xs-max) {
        margin-top: -45px;
    }
}

.navbar-nav {
    margin-left: -15px;
    font-weight: 400;

    .dropdown-menu {
        border: none;

        > li {
            > a {
                font-weight:400;
                color: $mb-nav-text;
                padding: 10px 15px;

                &:hover {
                    color: $primary;
                    background: transparent;
                }
            }

            &.active > a {
                color: $white;

                &:hover {
                    color: $primary;
                }
            }
        }

    }
}