.grouped-content-tabs{
   .nav-tabs{
       margin-bottom: $pad-medium;
   }
}

.grouped-content-accordion {
    .fa {
        color: $accordion-chevron;
    }

    h3 {
        margin: 0 0 $pad-small 0;
        padding: 0 0 $pad-small 0;
        border-bottom: 2px solid $accordion-separator;

        a {
            text-decoration: none;
        }
    }


}