#showcase {
    .caption {
        background: $grey;

        h2 {
            display: inline-block;
            margin: 0;
            padding: $pad-small $pad-large*2 $pad-small 0;
            color: $showcase-title;
            font-family: $headings-font-family;
            font-size: $font-size-h2;
            font-weight: 600;
        }

        @media (min-width: $screen-sm-min) {
            position: absolute;
            bottom: $pad-large;
            left: 0;
            background: rgba(255, 255, 255, 0.8);

            h2 {
                font-size: $font-size-h1;
            }
        }
    }
}