//$mb-calendar-bg: $secondary;
//$mb-buttons-bg: $primary;
//$mb-buttons-color: $white;
//$mb-calendar-border: $secondary-pale;
.fc.fc-unthemed {
    margin-bottom: $pad-large;

    .fc-view-container {
        border: $pad-small solid $mb-calendar-bg;
    }

    .fc-day-header {
        background: $mb-calendar-bg;
        color: $white;
        border-color: $mb-calendar-bg;
    }

    .fc-head-container,
    td {
        border-color: $mb-calendar-bg;
    }

    .fc-event {
        border-radius: 0;
        box-shadow: none !important;
    }

    .fc-button {
        border-radius: 0;
        background: $primary;
    }


}
