body {
    min-width: 320px;
}

img {
    max-width: 100%;
    height: auto;

    &.media-object {
        max-width: none;
    }
}

dt {
    float: left;
    clear: left;
    width: 30%;
    font-weight: bold;
}

dd {
    margin: 0 0 0 30%;
    padding: 0 0 0.5em 0;
}

.featherlight-content {
    max-width: 90%;
}