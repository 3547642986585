.content-feed {
}

.content-feed-header {
    @include list-header($primary);
}

.content-feed-body {
    @include list-body($primary-pale, $primary);
    padding: 0 $pad-small;
}

.feed-item {
    padding: $pad-medium 0;
    border-top: 1px solid $primary;

    &:first-child,
    .content-feed-slideshow & {
        border-top: none;
    }

    h3 {
        margin-top: 0;
    }
}