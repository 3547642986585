.events-list-header {
    @include list-header($secondary);
}

.events-list-body {
    @include list-body($secondary-pale, $secondary);

    h3 {
        min-height: 34px;
    }

    ul {
        margin-bottom: 0;
    }

    li {
        margin-bottom: $pad-medium;
    }

    @media(min-width: $screen-sm-min) {
        ul {
            display: flex;
        }

        li {
            flex-basis: 30%;
            flex-grow: 1;
            padding: 0 $pad-large 0 $pad-small;
            border-left: 1px solid $secondary;
            margin-bottom: 0;

            &:first-child {
                border: none;
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    .description {
        // a crude but effective way of limiting the amount of content that displays in the description
        height: 58px;
        display: block;
        overflow: hidden;
        margin-bottom: $pad-small;
    }
}