.content-block{
    margin-bottom:$pad-separate-content;
}


.standfirst {
  font-size: $font-size-large;
}

.text-pullout,
.cta {
    padding: $pad-medium;

    p:last-child {
        margin-bottom: 0;
    }

    h3 {
        margin-top: 0;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    .cta-button {
        @extend .btn;
        @extend .btn-primary;
        margin-bottom: 0 !important;
    }

    &.cta-highlighted {
        .cta-button {
            @extend .btn-default;
        }
    }

}

// CTAs and Text pullouts can define a background style:
.bg-style-plain {
    padding: 0;
}

.bg-style-light {
    background: $bg_style_light;
}

.bg-style-light-secondary {
    background: $bg_style_light_secondary;
}

.bg-style-bold {
    background: $bg_style_bold;
}

.bg-style-bold-secondary {
    background: $bg_style_bold_secondary;
}

.bg-style-bold,
.bg-style-bold-secondary {
    color: $white;
    font-size: $font-size-large;

    h3,
    a {
        color: $white;
    }

    .btn-primary {
        @extend .btn-default;
    }
}

.cta {
    @extend .clearfix;

    img {
        width: 100%;
    }

    p {
        margin-bottom: 0;
    }

    .btn {
        margin-top: 10px;
    }
}

.cta-post-and-img {
    img {
        width: 35%;
        max-width: 200px;
        margin: 0 0 5% 5%;
    }
}

.content-item {
    padding: 0 0 $pad-medium 0;
    border-bottom: 2px solid $primary;


    h3 {
        margin-top: 0;
    }
}

.standard-text-block-with-image{
    .content-wrapper{
        display: flex;
        @media screen and (max-width:$screen-xs-max-new){
            flex-wrap: wrap;
        }

        margin: -$grid-gutter-width/2;
        @media screen and (max-width:$screen-xs-max){
            margin: -$grid-gutter-width/3;
        }


        .image{
            flex-shrink: 0;
            margin: $grid-gutter-width/2;
            @media screen and (max-width:$screen-xs-max){
                margin: $grid-gutter-width/3;
            }
        }

        .content{
            margin:$grid-gutter-width/2;
            @media screen and (max-width:$screen-xs-max){
                margin: $grid-gutter-width/3;
            }
        }

    }
}

.documents {
    .media {
        border-bottom: 2px solid $document-separator;
        margin: $pad-small 0;
    }

    .file-icon {
        padding: 0;
        text-align: center;
    }

    a {
        word-break: break-all;
        color: $document-link;
    }

    p {
        margin: 0 0 3px 0;
    }
}

.grouped-content-tabs {
    .nav-tabs {
        a {
            color: $tab-text-colour;
            text-decoration: none;
        }
    }
}

.twitter-feed {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    .twitter-feed-header {
        @include list-header($primary);
    }
    h2 {
        padding: $pad-small;
        margin: 0;
    }
}