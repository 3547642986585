.content-latest {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .tease {
    width: calc(50% - #{$pad-medium}/2);
    min-width: 200px;

    &:nth-child(odd) {
      margin-right: $pad-medium;
    }

    .read-more {
      @extend .btn;
      @extend .btn-default;
    }
  }
}