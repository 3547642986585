.tease-post {
    margin-bottom: $pad-large;
    h2 {
        margin-bottom: 2px;
    }

    p {
        margin-top: 10px;
    }
}

.news-archives {
    ul {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2 {
        border-bottom: 2px solid #E7235B;
        padding-bottom: 30px;
        margin-bottom: 0;
    }
}

.post-date {
    margin-bottom: $pad-medium;
    line-height: $font-size-h1;
    float: right;
    color: $secondary;
}