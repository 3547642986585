.image-links {
    display: flex;
    margin-bottom: $pad-large;
}

.image-link-item {
    flex-basis: 33%;
    //border: 2px solid $primary;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:not(:first-child) {
        margin-left: $pad-large;
    }

    img {
        width: 250px;
        margin-bottom: $pad-medium;

        transition: all .3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    @media(min-width: $screen-sm-min) {
        h3 {
            font-size: $font-size-h2;
        }

    }

    div {
        padding: $pad-large 0;
        text-align: center;
    }
}

.colour-primary,
.colour-primary h3{
    color: $primary !important;
}

.colour-secondary,
.colour-secondary h3 {
    color: $secondary !important;
}