.partners-list {
    background: $primary-pale;
    padding: $pad-small;

    li {
        display: inline-block;
        width: 100%;

        @media(min-width: $screen-md-min) {
            width: 49%;
        }

        @media(min-width: 500px) and (max-width: $screen-sm-max) {
            width: 49%;
        }


    }
    strong {
        margin-right: 10px;
        color: white;
        font-weight: 700;
        width: 28px;
        height: 28px;
        line-height: 28px;
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        text-indent: 0;
        background: $secondary;
        flex-shrink: 0;
    }

    a {
        color: $black;
        //background: $primary-pale;
        padding: $pad-small / 2;
        display: flex;

        &:hover, &:active {
            background: $primary;
            color: $white;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

#partnerMap {
    width: 100%;
    display: inline-block;
    position: relative;
    max-height: 500px; // This is optional, but stops it getting stupidly tall on wide screens

    &:after {
        padding-top: 100%;
        display: block;
        content: '';
    }

    margin-bottom: $pad-large;
}

.page-consortium {
    .featherlight .featherlight-close-icon {
        right: 10px;
        top: 10px;
        color: $secondary;
        font-weight: 700;
        font-size: $font-size-h2;
    }
}

.partner {
    img {
        margin-bottom: $pad-medium;
    }
}